import Typography from '@mui/material/Typography';
import React, { useCallback, useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { withInitializedUser } from 'src/components/InitializedUser/WithInitializedUser';
import { NotificationService } from 'src/components/Notifications/Notifications';
import { composeArticleIdTextInput } from 'src/helpers/Helpers';
import { BRAND } from 'src/models/Article';
import { WithArticlesContext } from 'src/pages/ArticleLandingPage/store/ArticlesContext';
import {
  depublishArticle,
  getCategories,
  GetCategoriesResponse,
  getFeatureToggles,
  GetFeatureTogglesResponse,
  UpdateCategories,
  updateCategoryAction,
  updateFeatureToggle,
} from 'src/services/ArticleService';
import { useAuthContext } from 'src/services/Auth/AuthContext';
import Footer from '../../components/Footer/Footer';
import Category from '../Cpanel/components/Categories/Category';
import FeatureFlag from '../Cpanel/components/GeneralFeatureFlags/FeatureFlag';
import Header from '../Cpanel/components/Header/Header';
import UnpublishArticle from '../Cpanel/components/UnpublishArticle/UnpublishArticle';
import './cpanel.scss';

export const Cpanel = () => {
  const { brand } = useAuthContext();
  const [categories, setCategories] = useState<Array<GetCategoriesResponse>>([]);
  const [featureToggles, setFeatureToggles] = useState<Array<GetFeatureTogglesResponse>>([]);

  const [unpublishArticleCmsId, setUnpublishArticleCmsId] = useState<string>('');

  const handleUnpublishArticleCmsId = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUnpublishArticleCmsId(event.target.value.trim());
  };

  const handleCategorySwitch = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!brand) {
      return;
    }

    const updatedCategories = [...categories];
    const categoryToUpdate = updatedCategories[index];
    categoryToUpdate.autoPublish = event.target.checked;

    if (event.target.checked) {
      categoryToUpdate.dayRange = 'MON-FRI';
      categoryToUpdate.timeRange = '07:00-18:00';
    } else {
      categoryToUpdate.dayRange = '';
      categoryToUpdate.timeRange = '';
    }

    const payLoad: UpdateCategories = {
      brand,
      category: categoryToUpdate.value,
      autoPublish: categoryToUpdate.autoPublish,
      dayRange: categoryToUpdate.dayRange.trim(),
      timeRange: categoryToUpdate.timeRange.trim(),
    };

    patchCategory(payLoad);
    setCategories(updatedCategories);
  };

  const handleCategoryUpdate = (index: number, dayRange: string, timeRange: string) => {
    if (!brand) {
      return;
    }

    const updatedCategories = [...categories];
    const categoryToUpdate = updatedCategories[index];

    categoryToUpdate.dayRange = dayRange;
    categoryToUpdate.timeRange = timeRange;

    const payLoad: UpdateCategories = {
      brand,
      category: updatedCategories[index].value,
      autoPublish: updatedCategories[index].autoPublish,
      dayRange: updatedCategories[index].dayRange,
      timeRange,
    };
    patchCategory(payLoad);
    setCategories(updatedCategories);
  };

  const patchCategory = (payload: UpdateCategories) => {
    trackPromise(updateCategoryAction(payload))
      .then((_) => {
        NotificationService.success('Successfully changed category: ' + payload.category);
      })
      .catch((error: string) => {
        NotificationService.error(error);
      });
  };

  const handleUnpublishArticle = () => {
    const articleId = composeArticleIdTextInput(unpublishArticleCmsId, brand);
    if (!articleId.startsWith(`${brand}___`)) {
      NotificationService.error('Article-ID brand must be identical with its source');
      return;
    }

    unpublishArticle(articleId);
  };

  const unpublishArticle = (articleId: string) => {
    trackPromise(depublishArticle(articleId))
      .then((_) => {
        NotificationService.success(`Successfully depublished article with ID ${articleId}`);
        setUnpublishArticleCmsId('');
      })
      .catch((error: string) => {
        NotificationService.error(error);
      });
  };

  const fetchCategories = useCallback(() => {
    trackPromise(getCategories(brand as BRAND))
      .then((res) => {
        setCategories(res);
      })
      .catch((error) => {
        NotificationService.error(error);
      });
  }, [brand]);

  useEffect(() => {
    if (brand) {
      fetchCategories();
    }
  }, [brand, fetchCategories]);

  const fetchFeatureToggles = useCallback(
    (brand: BRAND | null) => {
      if (!brand) return;
      trackPromise(getFeatureToggles(brand))
        .then((res) => {
          setFeatureToggles(res);
        })
        .catch((error) => {
          NotificationService.error(error);
        });
    },
    [setFeatureToggles]
  );

  useEffect(() => {
    fetchFeatureToggles(brand);
  }, [fetchFeatureToggles, brand]);

  const patchFeatureToggle = (id: string, status: boolean) => {
    trackPromise(updateFeatureToggle(id, status))
      .then((_) => {
        NotificationService.success(`Successfully changed Feature Toggle: ${id}`);
        fetchFeatureToggles(brand);
      })
      .catch((error: string) => {
        NotificationService.error(error);
      });
  };

  return (
    <>
      <Header title="Feature Flags" dataTestId="cpanel-feature-toggle-header" />
      <article className="cpanelList">
        {featureToggles.map((featureToggle, index) => (
          <FeatureFlag
            key={index}
            name={featureToggle.label}
            description={featureToggle.description}
            checked={featureToggle.on}
            onChange={(event) => {
              patchFeatureToggle(featureToggle.key, event.target.checked);
            }}
            dataTestId="feature-toggle-component"
          />
        ))}
      </article>

      <Header
        title="Schedule auto-publish ressorts"
        tooltip={{
          title: (
            <Typography className="tooltipTitle">
              For the dates, follow the (MON-TUE-WED-THU-FRI-SAT-SUN) pattern, e.g. (MON-FRI), (WED-SUN)
              <br />
              <br />
              For the times, follow (HH:mm-HH:mm) in Berlin timezone, e.g. (00:00-18:00), (18:00-23:59)
            </Typography>
          ),
        }}
        dataTestId="cpanel-category-header"
      />
      <article className="cpanelList">
        <div>
          {categories.map((category, index) => {
            return (
              <Category
                key={category.value}
                name={category.name}
                checked={category.autoPublish}
                categoryDays={category.dayRange}
                categoryTimes={category.timeRange}
                onChange={handleCategorySwitch(index)}
                onUpdate={(dayRanged, timeRange) => handleCategoryUpdate(index, dayRanged, timeRange)}
                dataTestId="category-component"
              />
            );
          })}
        </div>
      </article>

      <Header title="Unpublish Article by ID" dataTestId="cpanel-unpublish-article-header" />
      <article className="cpanelList">
        <UnpublishArticle
          articleCmsId={unpublishArticleCmsId}
          onChange={handleUnpublishArticleCmsId}
          onBtnClick={handleUnpublishArticle}
          dataTestId="unpublish-article-component"
        />
      </article>

      <Footer />
    </>
  );
};
export default withInitializedUser(WithArticlesContext(Cpanel));
